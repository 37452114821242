import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import slugify from "slugify";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import styles from "./home.module.css";
import Layout from "../components/Layout";

function HomePage({ data }) {
  const { home, projects } = data;

  return (
    <Layout location="Home">
      <main>
        <section className={styles.homeTxt}>
          {documentToReactComponents(home.content.json)}
        </section>

        <section className={styles.actions}>
          <span>
            <Link to="/contact">Pošaljite nam upit &rarr;</Link>
          </span>
          <span>
            <span role="img" aria-label="books">
              📚
            </span>
            {" "}Zaštita knjiga — {" "}
            <a
              href="https://ldc-zastitaknjiga.com/"
              target="_blank"
              rel="noreferrer"
            >
              ldc-zastitaknjiga.com
            </a>{" "}
            ↗
          </span>
        </section>

        <section className={styles.projects}>
          <h1>Projekti</h1>

          {projects.nodes.map((project, idx) => {
            const path = slugify(project.projectTitle, {
              remove: /[$*_+~.()'"!\-—:@]/g,
              lower: true,
            });

            return (
              <Link key={idx} to={`/${path}`}>
                <article className={styles.project}>
                  <Img alt="" fluid={project.headerPhoto.fluid} />
                  <h2>{project.projectTitle}</h2>
                </article>
              </Link>
            );
          })}
        </section>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query HomeQuery {
    home: contentfulStaticPage(
      contentful_id: { eq: "5dro5R2A5mYBvbquKzMEdK" }
    ) {
      content {
        json
      }
    }
    projects: allContentfulProject {
      nodes {
        projectTitle
        headerPhoto {
          fluid(maxWidth: 568) {
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
  }
`;

export default HomePage;
